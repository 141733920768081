




















































































import {
  defineComponent,
  ref,
  computed,
  SetupContext,
  ComputedRef,
  nextTick,
  useStore,
} from '@nuxtjs/composition-api'
import SectionCategories from './sections/SectionCategories.vue'
import SectionMobileNavigation from './sections/SectionMobileNavigation.vue'
import SectionNavigation from './sections/SectionNavigation.vue'
import SectionTabs from './sections/SectionTabs.vue'
import SectionSearch from './sections/SectionSearch.vue'
import SectionNotifs from './sections/SectionNotifs.vue'
import SectionSearchMobile from './sections/SectionSearchMobile.vue'
import { HeaderInfo, ThemeType } from '@/contracts/Settings'

export default defineComponent({
  components: {
    SectionCategories,
    SectionMobileNavigation,
    SectionNavigation,
    SectionTabs,
    SectionSearch,
    SectionSearchMobile,
    SectionNotifs,
  },
  setup(_props, ctx: SetupContext) {
    // const { headerHeight } = useHeaderHeight()
    const store = useStore()
    const headerInfo = computed(
      () => ctx.root.$store.getters['settings/headerInfo'] as HeaderInfo
    )
    const activeTabMenu = ref(false)
    const activeMobileMenu = ref(false)
    const tabElement = ref(null)
    // const headroom = ref(null)
    const category = ref({})
    const showPopup = ref(false)
    const isDisabledActiveTab = ref(true)
    const isStartSearch = ref(false)
    const isDesktopMq: ComputedRef<Boolean> = computed(
      () => ctx.root.$store.getters['mediaQuery/isDesktopMq']
    )
    const showPopupFaq: ComputedRef<Boolean> = computed(
      () => ctx.root.$store.getters['popupBuyOnline/getShowFaqPopup']
    )
    const isDesktopSearch = computed(
      () => isStartSearch.value && isDesktopMq.value
    )
    const isMobileSearch = computed(
      () => isStartSearch.value && !isDesktopMq.value
    )
    const pagePath = computed(() => ctx.root.$route.path)
    const allCategories = [...headerInfo.value.headerNavLinks]
    const { state } = useStore()
    const setActiveTab = (
      element: any,
      index: any,
      isMobileTablet: boolean = false
    ): void => {
      tabElement.value = element
      if (isMobileTablet) {
        allCategories.forEach((item: any) => {
          const categories = [...item.categories]
          categories.forEach((categoryItem: any) => {
            if (categoryItem.id === index) {
              category.value = categoryItem
            }
          })
        })
      } else {
        category.value = categories.value[index]
      }
      activeMobileMenu.value = false
      activeTabMenu.value = true
      isDisabledActiveTab.value = false
      showPopup.value = false
      if (showPopupFaq.value) {
        ctx.root.$store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
      }
    }
    const backToMenu = () => {
      activeTabMenu.value = false
      isStartSearch.value = false
      nextTick(() => (activeMobileMenu.value = true))
    }
    const closeSearch = (val) => {
      if (val?.closePopups) {
        closeAllPopups()
      }
      isStartSearch.value = false
    }
    const closeAllPopups = () => {
      isStartSearch.value = false
      activeTabMenu.value = false
      activeMobileMenu.value = false
      showPopup.value = false
      isDisabledActiveTab.value = true
      if (showPopupFaq.value) {
        ctx.root.$store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
      }
    }
    const startSearch = () => {
      isStartSearch.value = true
    }

    const togglePopup = (): void => {
      ctx.root.$store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
      showPopup.value = !showPopup.value
      isDisabledActiveTab.value = true
      if (activeMobileMenu.value) {
        activeMobileMenu.value = false
      }
      if (showPopupFaq.value) {
        activeTabMenu.value = false
      }
    }
    const closeTabMenu = () => {
      activeTabMenu.value = false
      isDisabledActiveTab.value = true
      showPopup.value = false
    }
    const onClickBurger = (): void => {
      if (activeMobileMenu.value) {
        activeMobileMenu.value = false
      } else {
        activeMobileMenu.value = true
      }
      if (activeTabMenu.value) {
        activeTabMenu.value = false
        activeMobileMenu.value = false
      }
      if (showPopup.value) {
        showPopup.value = false
        activeMobileMenu.value = false
      }
      if (isStartSearch.value) {
        isStartSearch.value = false
        activeMobileMenu.value = false
      }
      if (showPopupFaq.value) {
        ctx.root.$store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
        activeMobileMenu.value = false
      }
    }

    // const initHeadroom = () => {
    //   headroom.value = new Headroom(ctx.refs.header, {
    //     offset: headerHeight.value,
    //   })
    //   headroom.value.init()
    // }
    // watch(headerHeight, initHeadroom)
    // onMounted(initHeadroom)
    const currentLanguage = computed(
      () => ctx.root.$store.getters['settings/curLang']
    )
    const currentParentRoute = computed(() => {
      return headerInfo.value.headerNavLinks.find((_link) => {
        return (
          pagePath.value.includes(_link.url) &&
          _link.url !==
            (currentLanguage.value === 'ru' ? '/' : `/${currentLanguage.value}`)
        )
      })
    })
    const parentActiveId = ref('')
    const isShowActivated = ref(false)
    const categories = computed(() => {
      const lang = ctx.root.$store.getters['settings/curLang']
      const langMainPath = lang === 'ru' ? '/' : `/${lang}`
      const el = currentParentRoute.value || {}
      const defaultEl =
        headerInfo.value.headerNavLinks.find((item: any) => {
          return (
            item.url ===
            (currentLanguage.value === 'ru' ? '/' : `/${currentLanguage.value}`)
          )
        }) || {}
      isShowActivated.value = (el.id ? el.url : defaultEl.url) === langMainPath
      parentActiveId.value = el.id ? el.id : defaultEl.id
      store.commit(
        'headerNavLinks/SET_CATEGORIES',
        el.id ? el.categories : defaultEl.categories
      )
      return el.id ? el.categories : defaultEl.categories
    })
    const isVipTheme = computed(() => state.settings.theme === ThemeType.vip)

    return {
      togglePopup,
      onClickBurger,
      showPopup,
      headerInfo,
      activeTabMenu,
      activeMobileMenu,
      tabElement,
      categories,
      category,
      setActiveTab,
      backToMenu,
      isDesktopMq,
      isMobileSearch,
      isDesktopSearch,
      closeTabMenu,
      isDisabledActiveTab,
      parentActiveId,
      pagePath,
      startSearch,
      isStartSearch,
      closeSearch,
      closeAllPopups,
      showPopupFaq,
      isShowActivated,
      isVipTheme,
    }
  },
})
